import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { map} from 'rxjs/operators';
import { User, UserApiReturn } from '../models/user';
import { API_SERVICE_TOKEN } from './api.service';
import { KeycloakEventType, KeycloakEvent } from 'keycloak-angular';
import { KeycloakServiceContext } from './../service/keycloak.service';
import { ROLE_SEP } from '../shared-components/wbce-button/wbce-button.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  subs: Subscription[] = [];
  currentUser?: User;
  currentUser$ = new ReplaySubject(1);
  keycloakInit:boolean = false;

  router = inject(Router);
  api = inject(API_SERVICE_TOKEN);
  #keycloakService = inject(KeycloakServiceContext);

  constructor(
  ) {

    this.checkSessionStatus = this.checkSessionStatus.bind(this);

    document.addEventListener("visibilitychange", this.checkSessionStatus);
    setInterval(() => {
      this.checkSessionStatus()
    }, 60000);
  }

  setKeycloakInit(status : boolean, err? : any){
    this.keycloakInit = status;
  }

  checkSessionStatus() {
    if (!document.hidden) {
      console.log('Tab/window is visible. Checking session status...');
      // Call your method to check session status here
      this.api.quiSuisjeKeycloak().subscribe(() => {
        console.log('Session ok');
      });
    }
  }

  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.checkSessionStatus);
    //tant que le service est build en root, est inutile
    for (let s of this.subs) {
      s.unsubscribe();
    }
  }

  onKeycloakChange(e: KeycloakEvent) {
    {
      if (e.type == KeycloakEventType.OnTokenExpired) {
        this.#keycloakService.updateToken(240).then(() => this.reLoadUser());
      } else {
        this.reLoadUser().subscribe();
      }
    }
  }

  logIn(provider: string, finalPath = '') {
    /*
     * oauth=> redirection ; donc on quitte la page a la suite de ceci
     */
    return this.api.connect(provider, finalPath, {}).pipe(
      map((url:string) => {
        window.location.href = url;
      })
    );
  }

  branchNewOauth(provider, finalPath = '') {
    return this.api
      .connect(provider, finalPath, {
        params: {
          state: 'branch-to-existing-user',
        },
      })
      .pipe(
        map((url:string) => {
          window.location.href = url;
        })
      );
  }

  isLoggedIn() {
    /**
     * A appeler une fois par instance de l'app
     */
    if (this.currentUser) {
      return of(true);
    } else {
      return this.reLoadUser().pipe(
        map(() => {
          return true;
        })
      );
    }
  }


  calculatePermissions(roles){
    let permissions =  new Map();
    roles.forEach((r)=>{
      r.permissions.forEach((p)=>{
        let permissionName = ""
        if(!p.projectId) {
          permissionName += "all"
        } else {
          permissionName +=p.projectId
        }
        permissionName += ROLE_SEP
        if(!p.ctxt) {
          permissionName += "all"
        } else {
          permissionName +=p.ctxt
        }

        let oldScopes = permissions.get(permissionName)
        let scopes = p.scopes;
        if(oldScopes){
          scopes = [...new Set([...scopes, ...oldScopes])];
        }
        permissions.set(permissionName, scopes)
      })
    })

    return permissions;
  }

// load auth from keycloak
  reLoadUser() {
    return this.api.quiSuisjeKeycloak().pipe(
      map((user: UserApiReturn)=>{
        this.currentUser = User.createFromApi(user)
        this.currentUser$.next(this.currentUser);
    }));
  }

  disconnect() {
    return this.#keycloakService
      .logout()
      .then(() => this.#keycloakService.clearToken())
      .then(() => {
        this.currentUser = undefined;
        this.currentUser$.next(undefined);
        this.router.navigateByUrl('/'); // force the authguard and go to login
      });
  }
}
