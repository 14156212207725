import { contextes } from "../enums/contextes";
import { GitProvider } from "./git-provider";
import { deploymentStates, Role, Service } from "./service";


 const NB_MAX_SERVICE = 7;

  export class Project{
    _id : string | number;
    projectName? : string;
    services : Service[] = [];
    domains:string[];
    currentDomain: string;
    currentRootService : string;
    currentDomainUpdatedAt : string;

    cloudEnv? : {
      account?: string;
      region?: string;
    };

    cloudConfig? : {
      [provider: string] :  {
        project : string,
        zone : string
      }
    };
    cloudProvider? : string;

    roles? : Role[];
    picture?: string;
    description?: string;
    domain?: string;
    deployment? : boolean;
    gitProvider?: GitProvider;

    constructor(){

    }

    getContextes(): (contextes|string)[]{
      const listeContextes : (contextes|string)[]= [];
      if(this.services?.length>0){
        if(this.services[0][contextes.PRODUCTION]) listeContextes.push(contextes.PRODUCTION)
        if(this.services[0][contextes.STAGING]) listeContextes.push(contextes.STAGING)
        return listeContextes.concat(this.services[0].onDemandContextes.map(c=>c.name)).sort((a,b)=>{
          const order = {
            [contextes.STAGING] : 0,
            [contextes.PRODUCTION]: 2
          }
          const indexA = order[a] || 1;
          const indexB = order[b] || 1;
          return (indexA - indexB)
        })
      }
      return listeContextes
    }

    static createFromApi(infos){
      const project = new Project();
      project.projectName = infos.projectName;
      project._id = infos._id;
      if(infos.cloudEnv) project.cloudEnv = infos.cloudEnv;
      if(infos.cloudProvider) project.cloudProvider = infos.cloudProvider;
      if(infos.cloudConfig) project.cloudConfig = infos.cloudConfig;
      if(infos.gitProvider) project.gitProvider = infos.gitProvider;
      if(infos.roles) project.roles = infos.roles;
      if(infos.picture) project.picture = infos.picture;
      if(infos.description) project.description = infos.description;
      if(infos.deployment) project.deployment = infos.deployment;
      if(infos.domains) project.domains = infos.domains;
      if(infos.currentDomain) project.currentDomain = infos.currentDomain
      if(infos.currentRootService) project.currentRootService = infos.currentRootService
      if(infos.currentDomainUpdatedAt) project.currentDomainUpdatedAt = infos.currentDomainUpdatedAt
      for(const sApi of infos.services ){
        project.services.push(
            Service.createFromApi(sApi)
        )
      }
      return project;
    }


        // CREATION
    creationState() : deploymentStates{
      let nb_creation = 0;
      let nb_failure = 0;
      let nb_progress = 0;
      let nb_warning = 0;


      this.services?.forEach(s => {
        switch (s.state) {
          case deploymentStates.IN_PROGRESS:
            nb_progress++;
            break;
          case deploymentStates.ERROR:
            nb_failure++;
            break;
          case deploymentStates.SUCCESS:
            nb_creation++;
            break;
          case deploymentStates.WARNING:
            nb_warning++;
            break;
          default:
            nb_progress++;
            break;
        }
      })

      if(nb_failure === this.services?.length)
        return deploymentStates.ERROR;
      if(nb_creation>1)
        return deploymentStates.SUCCESS;
      return  deploymentStates.IN_PROGRESS;

    }

    isDisabled():boolean{
        return  this.creationState() === deploymentStates.IN_PROGRESS;
    }

    isMaxReached():boolean{
      return this.services.length>=NB_MAX_SERVICE
    }


  }

