export const environment = {
    api : {
      domain : 'wbce.webcapsule.voxatool.com',
      protocol : 'https',
      port : undefined
    },
    keycloak: {
      domain : 'keycloak.webcapsule.voxatool.com',
      protocol : 'https',
      port: undefined
    },
    production: true,
    mock : false,
    directusOnly : false,
    googleTagID : '',
    clarity : '',
    datadog :{
      applicationId: '371bb142-4e7f-4bb3-a233-c473dff54fce',
      clientToken: 'pub511722f3e11aaebc51b2228720c40e71',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: 'voxaly-app-webcapsule',
      env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 90,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
  }
};


